import React from "react";
import { Link, graphql } from "gatsby";
import { withTranslation } from 'react-i18next';
//import kebabCase from "lodash/kebabCase";

import {BlogLayout} from "../../layouts";
import {SEO, HeroPlain} from "../../components";

class Blog extends React.Component {
  render() {
    const { data, categories, t } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <BlogLayout location={this.props.location} title={siteTitle}>
        <SEO title="All posts" />
        <HeroPlain background="/images/blog_hero.jpg">
          <h1 className={"font_second semibold font40"}>{t('blog.header')}</h1>
          <p className="font20 padding_top20 opacity9 text">
           {t('blog.description')}
          </p>
        </HeroPlain>
        <div className="content_11 padding_bottom50">
    	    <div className="container max_width1000 bg_white padding_left25 padding_right25 padding_top50 min_height500">
    	      <div className="flex blog">
      	      <section className="max_width600 font18 light dark_blue text right50">
                <div>
                  {posts.map(({ node }) => {
                    const title = node.frontmatter.title || node.fields.slug
                    return (
                      <div className="padding_bottom10" key={node.fields.slug}>
                        <h5>
                          <Link className="blue" to={node.fields.path}>
                            {title}
                          </Link>
                        </h5>
                       {/* <small>{node.frontmatter.date}</small> */}
                        <p className="blog__blog-post__content"
                          dangerouslySetInnerHTML={{
                            __html: node.frontmatter.description || node.excerpt,
                          }}
                        />
                      </div>
                    )
                  })}
              </div>
            </section>
            {categories && 
              <aside className="bg_sand padding_left25 padding_top25 padding_bottom25 padding_right25">
                {categories}
              </aside>
            }
          </div>
          </div>
          </div>
      </BlogLayout>
    )
  }
}

export default withTranslation()(Blog)

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC}, 
      filter:  { frontmatter: { date: { ne: null } } }
      ) {
      edges {
        node {
          excerpt
          fields {
            slug
            path
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            meta {
              name
              content
            }
            link {
              rel
              href
              hreflang
            }
            hero
          }
        }
      }
    }
  }
`
